import './App.css';
import logo from "./logo.jpg";
import example1 from "./example1.jpg";
import example2 from "./example2.jpg";
import example3 from "./example3.jpg";
import penne from "./penne.jpg";
import tortellini from "./tortellini.jpg";
import dewolaj from "./dewolaj.jpg";
import { Button, Image, Container, Nav, Navbar, Badge, Row, Col, OverlayTrigger, Tooltip, NavDropdown, Form, InputGroup, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faClock, faUser, faBook, faSearch, faComment, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faTiktok } from "@fortawesome/free-brands-svg-icons"
import { SocialIcon } from 'react-social-icons';

const Logo = () => <img src={logo} alt="" style={{margin: '0 auto', display: 'block'}} />;

const HomeIcon = ({color}) => <Nav.Item><NavLink><FontAwesomeIcon icon={faHome} color={color} /></NavLink></Nav.Item>;

const StyledNav = styled(Nav)`
    background-color: black;
    color: white;
    font-weight: bold;
`;

const Content = styled.div`
`;

const Title = styled.h1`
    font-size: 1.6em;
    margin-bottom: 5px;
    padding-bottom: 5px;
`;

const IngredientsTitle = styled.h3`
    font-size: 1.4em;
    display: block;
`;

const BlogPost = styled.div`
    border-bottom: solid 1px black;
    margin: 10px 0;
    padding-bottom: 5px;
`;

const BlogPostTitle = styled.h2`
    font-size: 2.2em;
    margin-bottom: 0;
`;

const Socials = styled.div`
    padding: 8px 0 0 0;
    font-size: 1.2em;
    color: #777;
`;

const BlogPostTime = styled.div`
    color: #555;
    font-size: 1.4em;
    padding: 2px 0 0 0;

    @media only screen and (max-width: 768px) {
        font-size: 1.1em;
    }
`;

const BlogPostTags = styled.div`
    margin: 3px 0;
`;

const Tag = styled(Badge)`
    margin: 2px;
`;

const BreakLine = styled.div`margin: 10px;`;

const BlogPostTag = ({children}) => <Tag bg="danger">#{children}</Tag>;
const BlogPostPicture = ({src}) => <Image src={src} alt="" style={{maxWidth: '500px', width: 'auto', height: '300px', margin: '0 auto', display: 'block'}} />;
const BlogPostEntry = ({children}) => <div>{children}</div>;
const BlogPostHeader = styled.div`
    display: flex;
`;

const BlogPostIngredientsContainer = styled.div`
    /*background-color: #FFFDD0;
    box-shadow: 0 0 1em #AAA880;*/
    padding: 10px;
    max-height: 250px;
    max-width: 250px;
    width: 250px;
    height: auto;
`;

const BlogPostIngredients = ({children}) => <BlogPostIngredientsContainer><IngredientsTitle>Potrzebne</IngredientsTitle>{children}</BlogPostIngredientsContainer>;

const HashTags = styled.div`
    padding-top: 10px;
`;

const ClockIcon = () => <FontAwesomeIcon icon={faClock} />;
const UserIcon = () => <FontAwesomeIcon icon={faUser} />;
const BookIcon = () => <FontAwesomeIcon icon={faBook} />;

const ExternalSocialLink = styled.a`
    color: inherit;
    text-decoration: none;

    &:hover {
        color: black;
    }
`;

const SocialIconLink = ({icon}) => <ExternalSocialLink><FontAwesomeIcon icon={icon} /></ExternalSocialLink>;

const BlogFooter = styled.footer`
    padding: 20px;
    text-align: center;
`;

const NavLink = styled(Nav.Link)`
    text-transform: uppercase;

    &, &:active {
        color: white;
    }

    &:hover, &:focus {
        color: gray;
    }
`;


const NavDropdown_ = styled(NavDropdown)`
    text-transform: uppercase;
`;

const NavDropdownItem = styled(NavDropdown.Item)`
    text-transform: uppercase;
`;

const Navigation = ({links, categories}) => {
    return (
        <Navbar sticky="top" id="responsive-navbar-nav" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <HomeIcon color="red"></HomeIcon>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse>
            <Nav>
                <Nav.Item><NavLink>Strona główna</NavLink></Nav.Item>
            {
                categories.map(category => <NavDropdown_ title={category.name} id={category.id}>
                    {category.subcategories.map(subcategory => <NavDropdownItem>{subcategory.name}</NavDropdownItem>)}
                </NavDropdown_>)
            }
            {
                links.map(link => <Nav.Item><NavLink>{link.name}</NavLink></Nav.Item>)
            }
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

const BlogPosts = ({posts}) => {
    return <>
        {posts.map(post => {
            return (
                <BlogPost>
                    <BlogPostHeader>
                        <Container>
                            <Row>
                                <Col lg={4}>
                                    <Carousel variant="dark">
                                        {post.pictures.map(picture => {
                                            return <Carousel.Item>
                                                <BlogPostPicture src={picture}></BlogPostPicture>
                                            </Carousel.Item>;
                                        })}
                                    </Carousel>
                                </Col>
                                <Col lg={8}>
                                    <BlogPostTitle>#{post.id} {post.title}</BlogPostTitle>
                                    <BlogPostTime><ClockIcon /> {post.date} r. o godz. {post.time} <UserIcon /> {post.author}</BlogPostTime>
                                    <Socials>
                                        <SocialIconLink icon={ faExternalLinkAlt } /> Zobacz też na:{' '}
                                        <SocialIconLink icon={ faInstagram } />{' '}
                                        <SocialIconLink icon={ faFacebook } />{' '}
                                        <SocialIconLink icon={ faTiktok } />{' '}
                                    </Socials>

                                    <Row>
                                        <Col>
                                            <BlogPostIngredients>
                                                <ul>
                                                    {post.ingredients.map(position => <OverlayTrigger placement={'left'} delay={{show: 250, hide: 250}} overlay={
                                                        <Tooltip>
                                                            Szukaj potraw zawierających {position}
                                                        </Tooltip>
                                                    }>
                                                        <li>{position}</li>
                                                    </OverlayTrigger>)}
                                                </ul>
                                            </BlogPostIngredients>
                                        </Col>
                                        <Col lg={4}>
                                            <HashTags>
                                                {post.tags.map(tag => <BlogPostTag>{tag}</BlogPostTag>)}
                                            </HashTags>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </BlogPostHeader>
                    <BlogPostEntry>
                        { post.entry }
                    </BlogPostEntry>
                    <BlogPostTags>
                        <Container>
                            <Row>
                                <Col style={{alignItems: 'center', display: 'flex'}}>
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    <Button size="sm" variant="danger" style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={ faComment } /> Skomentuj na blogu ({post.comments_count})</Button>
                                </Col>
                            </Row>
                        </Container>
                    </BlogPostTags>
                </BlogPost>
            );
        })}
    </>;
};

const menu = () => ([{name: "Dania mięsne"}, {name: "Dania rybne"}, {name: "Polecane"}, {name: "Współpraca"}, {name: "Kontakt"}]);
const categories = () => ([
    {id: 'obiady', name: 'Obiady', subcategories: [
        {id: 'szybkie', name: 'Szybkie obiady'},
        {id: 'pizza', name: 'Pizza'}
    ]},
    {id: "zupy", name: "Zupy", subcategories: [
        {id: "w-proszku", name: "W proszku"},
        {id: "w-płynie", name: "W płynie"}
    ]}
]);

const placekEntry = () => ({
    description: 'Chrupiący placek ziemniaczany z miękkim środkiem, wypełniony gulaszem duszonym na ciemnym piwie z papryka.',
    steps: [
        {
            title: 'Placek ziemniaczany na 2 porcje',
            ingredients: ['ok. 6 ziemniaków', 'jajko', '2 łyżki mąki pszennej', '1 mała cebula', 'sól'],
            instructions: [
                'Ziemniaki ścieram na tarce na drobnych oczkach. Przekładam starte ziemniaki na sitko. Pod sitkiem umieszczam miskę. Ziemniaki dociskam do dna aby pozbyć się nadmiaru wody.',
                'Przekładam odciśnięte ziemniaki do miski dodaję zebrana na spodzie miski skrobie, dodaję mąkę pszenna, mała cebulkę starta na tarce na drobnych oczkach, 1 jajko. Doprawiam solą do smaku. Mieszam do połączenia składników.',
                'Smażę na rozgrzanym oleju dzieląc masę na 2 części. Każda cześć przekładam na rozgrzany olej i rozprowadzam po całości. Smażę z obu stron na złoty kolor.'
            ]
        },
        {
            title: 'Gulasz',
            ingredients: [
                '500g karkówki',
                '2 cebule',
                'olej do smażenia',
                '1 papryka czerwona',
                '1 szklanka ciemnego piwa',
                '1 szklanka wody',
                '1 łyżeczka musztardy rosyjskiej',
                '2 ząbki czosnku',
                'przyprawy: majeranek, słodka papryka, wędzona papryka, liść laurowy, ziele angielskie, 2 gałązki świeżego tymianku',
                '1 łyżka mąki pszennej'
            ],
            instructions: [
                'Karkówkę kroję w kostkę. Olej rozgrzewam i smażę mięso aż się zarumieni z każdej strony. Dodaję cebulę pokrojoną w kostkę i czosnek. Gdy cebula zmięknie dodaję paprykę słodka i wędzona. Chwilkę całość podsmażam aby papryka uwolniła swój aromat. Następnie wlewam piwo, wodę, dodaję musztardę. Doprawiam majerankiem, zielem angielskim, liściem laurowym i świeżym tymiankiem. Pod przykryciem około 1h. Następnie dodaję pokrojoną paprykę i duszę przez następne 40 min. Pod koniec do szklanki dodaję kilka łyżek wody i łyżkę mąki. Mieszam do połączenia i wlewam do sosu. Mieszam aż sos zgęstnieje.',
                'Na talerze wykładam placek, na połowie placka układam gulasz następnie drugą częścią placka przykrywam gulasz. Podaję z śmietaną lub jogurtem naturalnym. U mnie posypana natka pietruszki i ogórek konserwowy.',
            ]
        }
    ]
});

const dewolajEntry = () => ({
    description: 'Dewolaj z chrupiąca skórka, nadziewany musztarda, boczkiem wędzonym,serem salami, i ogórkiem konserwowym. Do tego gotowane ziemniaki z koperkiem i fasolka szparagowa',
    steps: [
        {
            title: 'Kotlet dewolaj ( de volaille) na 3 sztuki',
            ingredients: ['3 nieduże filety z kurczaka', '3-4 łyżeczki musztardy', '3 plastry wędzonego boczku', '3 plastry sera salami lub inny', '1 ogórek pokrojony na mniejsze paseczki', 'sól, pieprz', 'maka pszenna  do obtoczenia', 'bułka tarta do obtoczenia', '1 jajko i odrobina mleka'],
            instructions: [
                'kurczaka rozbijam z każdej strony. Doprawiam solą i pieprzem. Smaruje musztarda,boczek kroje na mniejsze kawałki, układam plaster sera żółtego i paseczki ogórka. Zawijam tak by nadzienie było w środku. Każdy z kotletów owijam szczelnie w folie spożywcza i wkładam do lodówki na min.30 min.',
                'następnie obtaczam w mące pszennej, jajku rozmąconym z mlekiem i bułce tartej. W jajku i bułce obtaczam dwa razy.',
                'na patelnie wlewam olej. Gdy olej bedzue gorący zmniejszam leki grzanie wkładam pokolei kotlety i smażę z każdej strony na złoty kolor aż do uzyskania złotej panierki.'
            ]
        },
    ]
});

const penneEntry = () => ({
    description: 'Penne w sosie śmietanowo-cebulowym z soczystym filetem z kurczaka i prażonym słoneczkiem. To obiad, który za każdym razem wychodzi tak samo pyszny.',
    steps: [
        {
            title: 'Na 4 porcje:',
            ingredients: ['2 wysokie filety z kurczaka', 'słodka papryka, ostra papryka, sól, pieprz', 'olej', '300 ml śmietanki 18%', 'makaron typu penne ok. 400g', '4 cebule', 'słonecznik', '4 plastry sera żółtego', 'natka pietruszka lub bazylia do dekoracji', '1 łyżka masła'],
            instructions: [
                'Filety z kurczaka umieszczam w misce. Dodaje ok. 2-3 łyżki oleju, doprawiam solą,pieprzem,słodka i ostra papryka. Mieszam do połączenia tak by kurczak by przyprawy pokryły całego kurczaka.',
                'Na patelni rozgrzewam olej, na rozgrzany olej układam filety z kurczaka. Smażę bez przykrycia aż zrobi się złoty z jednej strony. Po przewróceniu na druga stronę dodaje masło i cebule pokrojone w piórka. I przykrywam pokrywka. Dusze do momentu az  kurczak będzie usmażony a cebula zmięknie.',
                'Na sucha patelnie przesypuje słonecznik i praże az się zarumieni',
                'usmażonego kurczaka przekładam na deskę aby „odpoczął”',
                'do cebuli wlewam śmietankę, mieszam przez kilka minut az lekko się zagęści. Dodaje ser żółty i ugotowany makaron. Mieszam do połączenia.',
                'na talerze wykładam makaron, dodaje kurczaka pokrojonego w plasterki. Posypuje prażonym słoneczkiem i dekoruje bazylia lub natka.'
            ]
        }
    ]
});

const tortelliniEntry = () => ({
    description: 'Tortellini w sosie pomidorowo-śmietanowym inspirowane popularnym sosem z dodatkiem wódki. Sos jest aromatyczny,wyrazisty i kremowy u mnie podany z makaronem tortellini z serem w środku.',
    steps: [
        {
            title: 'Na 2 porcje:',
            ingredients: ['1 opakowanie tortellini z serem', '1 cebula', '3 ząbki czosnku', '1 szklanka gorącej wody z makaronu', '5 łyżek koncentratu', 'niecałe pół szklanki śmietany 30%', '2 łyżki wódki', '1 łyżka masła', 'sól, pieprz, cukier, chili chipotle lub ostra papryka, słodka papryka', '1 łyżka posiekanej natki pietruszki', 'parmezan do podania'],
            instructions: [
                'makaron tortellini gotuje we wrzącej osolonej wodzie al dente',
                '1 cebule kroje w kostkę, wlewam odrobine oleju i szkle cebule.Pod koniec dodaje posiekany czosnek. Po dodaniu czosnku smażę jeszcze przesz około minutę.',
                'dodaje koncentrat, wódkę i mieszam aby alkohol odparował.',
                'następnie dodaje masło, czekam aż się rozpusci i dodaje wodę z ugotowanego makaronu i przyprawy. Całość cały czas gotuje bez przykrycia po około 10 min. dodaje słodka śmietankę. I ugotowany makaron.',
                'podaje od razu, na górę ścieram parmezan i dodaje posiekana natkę pietruszki'
            ]
        },
        {
            title: 'Gulasz',
            ingredients: [
                '500g karkówki',
                '2 cebule',
                'olej do smażenia',
                '1 papryka czerwona',
                '1 szklanka ciemnego piwa',
                '1 szklanka wody',
                '1 łyżeczka musztardy rosyjskiej',
                '2 ząbki czosnku',
                'przyprawy: majeranek, słodka papryka, wędzona papryka, liść laurowy, ziele angielskie, 2 gałązki świeżego tymianku',
                '1 łyżka mąki pszennej'
            ],
            instructions: [
                'Karkówkę kroję w kostkę. Olej rozgrzewam i smażę mięso aż się zarumieni z każdej strony. Dodaję cebulę pokrojoną w kostkę i czosnek. Gdy cebula zmięknie dodaję paprykę słodka i wędzona. Chwilkę całość podsmażam aby papryka uwolniła swój aromat. Następnie wlewam piwo, wodę, dodaję musztardę. Doprawiam majerankiem, zielem angielskim, liściem laurowym i świeżym tymiankiem. Pod przykryciem około 1h. Następnie dodaję pokrojoną paprykę i duszę przez następne 40 min. Pod koniec do szklanki dodaję kilka łyżek wody i łyżkę mąki. Mieszam do połączenia i wlewam do sosu. Mieszam aż sos zgęstnieje.',
                'Na talerze wykładam placek, na połowie placka układam gulasz następnie drugą częścią placka przykrywam gulasz. Podaję z śmietaną lub jogurtem naturalnym. U mnie posypana natka pietruszki i ogórek konserwowy.',
            ]
        }
    ]
});

const compose = entry => {
    return <>
        <p>{entry.description}</p>
        {entry.steps.map(step => <dl>
            <dt><h3>{step.title}</h3></dt>
            <dd>
                <h4>Przygotowuję</h4>
                <ul>
                    {step.ingredients.map(ingredient => <li>{ingredient}</li>)}
                </ul>
                <h4>Postępuję według kroków</h4>
                {
                    step.instructions.map(instruction => <p>{instruction}</p>)
                }
            </dd>
        </dl>)}
        <p>Smacznego 🥰</p>
    </>;
}

const post = (id, name, pictures, entry) => ({
    id: id,
    title: name,
    date: '15 sierpnia 2000',
    time: '14:22',
    author: 'Ola',
    pictures: pictures,
    ingredients: ['sól morska', 'mydło biały jeleń', 'powidło', '30 dag mięsa z indyka', 'trzy szklanki wody'],
    entry: compose(entry),
    tags: ['mięso', 'zupa', 'kuchnia domowa', 'polecane', 'danie dnia', 'pyszne'],
    comments_count: 72
});
const posts = () => [
    post("1", "Placek po węgiersku", [example1, example2, example3], placekEntry()),
    post("2", "Tortellini", [tortellini], tortelliniEntry()),
    post("3", "Dewolaj", [dewolaj], dewolajEntry()),
    post("4", "Penne", [penne], penneEntry())
];

function App() {
    return (
        <Container>
            <Logo></Logo>
            <hr />
            <Navigation categories={categories()} links={menu()}></Navigation>
            <hr />

            <Content>
                <Row>
                    <Col>
                        <Title>
                            <BookIcon /> Wpisy
                        </Title>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            <Form.Control type="text" placeholder="Wyszukaj potrawę, składnik, hashtag, ..." />
                        </InputGroup>
                    </Col>
                </Row>
                <BlogPosts posts={posts()}>
                </BlogPosts>
            </Content>

            <BlogFooter>Inspiracjekulinarnee &copy; 2021&ndash; Zobacz też:<BreakLine /><SocialIcon url="https://www.facebook.com/inspiracjekulinarnee-555333071665423" /> <SocialIcon url="https://www.instagram.com/inspiracjekulinarnee/" /> <SocialIcon url="https://www.tiktok.com/@inspiracjekulinarnee" /></BlogFooter>
        </Container>
    );
}

export default App;
